<template>
  <v-form>
    <img
      src="@/assets/logo.png"
      alt="logo"
      class="mb-5"
      :style="{
        height: $vuetify.breakpoint.mdAndUp ? '3.4rem' : '3rem'
      }"
    />
    <v-row dense v-if="mode == 1">
      <v-col cols="12" sm="6" md="6">
        <i-image v-model="imageUrl" :file.sync="user.profile.photo" />
      </v-col>
      <v-col cols="12" sm="6" md="6" align-self="center">
        <ValidationProvider
          vid="username"
          :name="$tc('username', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.username"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$tc('username', 1)"
            key="username-input"
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider
          vid="first_name"
          :name="$t('first_name')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.first_name"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$t('first_name')"
            key="first_name-input"
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider
          vid="last_name"
          :name="$t('last_name')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.last_name"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$t('last_name')"
            class="secondary--text"
            key="last_name-input"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6">
        <ValidationProvider
          vid="email"
          :name="$tc('email', 1)"
          rules="'required|email'"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.email"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$tc('email', 1)"
            class="secondary--text"
            key="email-input"
            :class="{ 'secondary--text': true }"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6">
        <ValidationProvider
          vid="phone"
          :name="$t('phone_number')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.profile.phone"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$t('phone_number')"
            key="phone-input"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6">
        <ValidationProvider
          vid="contact"
          :name="$tc('contact', 1)"
          rules=""
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.profile.contact"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$tc('contact', 1)"
            class="secondary--text"
            key="contact-input"
            :class="{ 'secondary--text': true }"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6">
        <ValidationProvider
          vid="web_url"
          :name="$t('web_url')"
          rules="url"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.profile.web"
            name="web_url"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$t('web_url')"
            key="web_url-input"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" sm="6">
        <ValidationProvider
          vid="address"
          :name="$t('address')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.profile.address1"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$t('address')"
            key="address-input"
            :class="{
              'secondary--text': true,
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6">
        <ValidationProvider
          vid="address2"
          :name="$t('address2')"
          rules=""
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.profile.address2"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$t('address2')"
            class="secondary--text"
            key="address2-input"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6">
        <ValidationProvider
          vid="city"
          :name="$t('city')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.profile.city"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$t('city')"
            key="city-input"
            :class="{
              'secondary--text': true,
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="4">
        <ValidationProvider
          vid="state"
          :name="$t('state')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.profile.state"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$t('state')"
            key="state-input"
            :class="{
              'secondary--text': true,
              'pr-1': $vuetify.breakpoint.smAndUp
            }"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="2">
        <ValidationProvider
          vid="zip_code"
          :name="$t('zip_code')"
          rules="required|numeric"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.profile.zip_code"
            filled
            background-color="white"
            color="secondary"
            :error-messages="errors[0]"
            :label="$t('zip_code')"
            class="secondary--text"
            key="zip_code-input"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6">
        <ValidationProvider
          vid="pw_confirm"
          :name="$tc('password', 1)"
          rules="required|min:8"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="user.password"
            filled
            background-color="white"
            color="secondary"
            ref="pw_confirm"
            :label="$tc('password', 1)"
            :error-messages="errors[0]"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            :append-icon="showPass ? 'fa-eye' : 'fa-eye-slash'"
            :class="{ 'pr-3': $vuetify.breakpoint.smAndUp }"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6">
        <ValidationProvider
          vid="confirm_field"
          :name="$tc('password', 2)"
          rules="required|confirmed:pw_confirm"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            validate-on-blur
            v-model="password2"
            filled
            background-color="white"
            color="secondary"
            :label="$tc('password', 2)"
            :error-messages="errors[0]"
            :type="showPass2 ? 'text' : 'password'"
            @click:append="showPass2 = !showPass2"
            :append-icon="showPass2 ? 'fa-eye' : 'fa-eye-slash'"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center">
        <v-btn color="error" @click="back()">
          {{ $t('back') }}
        </v-btn>
        <v-btn color="success" class="ml-3" :loading="load" @click="next()">
          {{ mode == 2 ? $t('register') : $t('next') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  data() {
    return {
      imageUrl: null,
      step: 1,
      mode: 1,
      load: false,
      showPass: false,
      showPass2: false,
      password2: '',
      user: {
        email: '',
        username: '',
        first_name: '',
        last_name: '',
        password: '',
        profile: {
          photo: null,
          phone: '',
          contact: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip_code: null,
          web: '',
          tax: false
        }
      }
    }
  },
  methods: {
    register() {
      this.$refs.form.validate().then((success) => {
        if (!this.errors.any()) {
          this.load = true
          var form_data = new FormData()
          for (var key in this.user) {
            if (key == 'profile') {
              let profile = JSON.parse(JSON.stringify(this.user[key]))
              if (
                profile.photo == null ||
                String(this.imageUrl).indexOf('http') != -1 ||
                String(profile.photo).indexOf(';base64') != -1 ||
                String(profile.photo).indexOf('http') != -1 ||
                String(this.imageUrl).indexOf('/sources/') != -1
              ) {
                form_data.delete('photo')
              }
              for (var key2 in profile) {
                form_data.append(
                  'profile.' + key2,
                  key2 !== 'photo' ? profile[key2] : this.user.profile[key2]
                )
              }
            } else {
              form_data.append(key, this.user[key])
            }
          }
          this.$api.user
            .create({
              form: form_data
            })
            .then((response) => {
              this.load = false
              this.$store.dispatch('alert', {
                type: 'success',
                text: `${this.$tc('user', 1)} ${this.$tc('registered2', 1)}`
              })
              this.$emit('registered')
            })
        }
      })
    },
    next() {
      if (this.mode == 1) {
        this.$refs.form.validate().then((success) => {
          if (!this.errors.any()) {
            this.mode = 2
          }
        })
      } else {
        this.register()
      }
    },
    back() {
      if (this.mode == 1) {
        this.$emit('registered')
      } else {
        this.mode = 1
      }
    }
  }
}
</script>
<style>
.v-text-field--filled > .v-input__control > .v-input__slot {
  background-color: white !important;
}
</style>
